import React from "react";
import PureComponent from "../pure";

import Detail from "../detailView/detail";
import ActivitiesTimeline from './ActivitiesTimeline/ActivitiesTimeline';
import ViewPanelMenu from '../viewPanelMenu/viewPanelMenu'
import ProductSelector from './Quotes/productSelector';
import MassUpdateContent from './MassUpdate/MassUpdateContent';
import EmailDetail from "../email/EmailDetail";
import EmailDetailHeader from "../email/EmailDetailHeader";
import ListViewSearch from "../list/ListViewSearch.js";

import  sAction  from "sAction";
import Error from "../Errors/ErrorView";
import Subpanels from "../subpanel/Subpanels"
import Loader from "../loader";
import RightPanelImage from "./RightPanelImage";

export default class RightPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.panel = React.createRef();
  }
  close() {
    const reloadOnClose = sAction.dataGet("rightPanel/reloadOnClose");
    const data = this.props.data.get("data");
    sAction.closeRightPanel();
    if(data.get("reloadList") != undefined && reloadOnClose === true){
      const way = data.get("reloadList")
      console.log(way);
      sAction.reloadList(0,1,way);
    }
  }
  openRecord(module,id){
    window.open("#detail/"+module+"/"+id,'_blank');
  }

  render() {
    const data = this.props.data;
    var content = data.content;
    var headerContent = null;

    const customWidth = data.get("customWidth")

    switch (content) {
      case "load":
        content = <Loader />;
        break;
      case "detailView":
        content = (
          <Detail
            key="detail"
            a={this.props.action}
            data={data.data.get("detail")}
            way="rightPanel/data/detail"
          />
        );
        headerContent = <React.Fragment>
            <ViewPanelMenu data={data.data.get("detail")} prefix="rightPanel/data/detail" />
            <div className="rightPanelClose icon-expandIcon" onClick={() => this.openRecord(data.data.getIn(["detail","module"]),data.data.getIn(["detail","id"]))}></div>
        </React.Fragment>
        break;
        case "productView":
          headerContent = (
            <div className="productSelectorListViewSearchContainer">
              <ListViewSearch prefix="rightPanel/data/listView" data={data.data.listView.filter} />
            </div>
          );

          //console.log("productView", );

          content = (
              <ProductSelector data={data.data} detailWay="view" />
          );
        break;

      case "emailRelations":
        content = (
          <div className="email__relations">
            <Subpanels data={data.data.get("detail").subpanels} way="rightPanel/data/detail" />
          </div>
        );
        break;
      case "emailView":
        content = (
          <div className="emailRightPanelContainer">
            <EmailDetail data={data.data} hideHeader />
          </div>
        );
        headerContent = (
          <EmailDetailHeader data={data.data} />
        );
        break;
      case "massUpdateView":
        content = (
            <MassUpdateContent data={data.data} />
        );
      break;
      case "activitiesTimelineView":
        let activitiesTimelinePrefix = "rightPanel";
        content = (<ActivitiesTimeline prefix={activitiesTimelinePrefix+"/data"} data={data.data}/>);
      break;
      case "error":
        content = (<Error data={data.data}/>);
        break;
      case "image":
        content = (<RightPanelImage data={data.data} />);
      break;
    }

    //pokud je aktivni prihlasit se jako je potreba posunout rightpanel niz
    const additionalHeight = {}
    if(document.querySelector(".headerAlertPanel")){
      additionalHeight.marginTop = '64px'
    }
    if(customWidth !== null){
      additionalHeight.width = customWidth
    }

    return (
      <div className="rightPanelContainer" style={additionalHeight} ref={this.panel}>
        <div className="rightPanelHeader">
          <div
            className="icon-Close rightPanelClose"
            onClick={() => this.close()}
          />
          {headerContent}
        </div>
        <div className="rightPanelContent">{content}</div>
      </div>
    );
  }
}
