import detailDefault from "../../detailDefault";

export default class detailacm_calibration_service extends detailDefault {
  load(sAction, data) {
    this.changeRequiredFields(sAction, data);
  }

  update(sAction, data) {
    switch(data.field) {
      case 'stage':
        this.changeRequiredFields(sAction, data);
      break;
    }
  }

  changeRequiredFields(sAction, data) {
    const stage = sAction.dataGet(data.prefix + '/fields/stage/value');
    sAction.dsClear();
    if (stage === 'held') {
      sAction.dsAdd("set", data.prefix + '/fields/date_performed/def/required', true);
      sAction.dsAdd("set", data.prefix + '/fields/performed/def/required', true);
    } else {
      sAction.dsAdd("set", data.prefix + '/fields/date_performed/def/required', false);
      sAction.dsAdd("set", data.prefix + '/fields/performed/def/required', false);
    }
    sAction.dsProcess();
  }
}
