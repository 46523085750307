import React from "react";

import MaterialDefault from './MaterialDefault'
import InputText from "./InputText";
import  sAction  from "sAction";

export default class Relate extends MaterialDefault {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      state: "fine",
      value: props.data.value,
      id: null,
      resultData: null,
      resultOpen: false,
      resultArrowIndex: -1,
    };
    this.searchInterval = null;
    this.input = React.createRef();
  }
  keyUp(event) {
    const keyCode = event.keyCode;
    if (keyCode === 13 && event.ctrlKey === false) {

      if(this.state.resultOpen == true){
        const item = this.state.resultData[this.state.resultArrowIndex]
        if(item != undefined){
          this.selectItem(item,true,false)
          this.setState({resultOpen: false,})
          this.input.current.value=item.name
        }
      }else{
        //this.save();
      }

    } else if (keyCode === 27) {
      this.cancel();
    }else if(keyCode === 40 || keyCode === 38) {
      this.changeArrowIndex(keyCode);
    } else if (keyCode !== 9 && keyCode !== 37 && keyCode !== 39) {
      this.waitForSearch();
    }
  }
  waitForSearch() {
    if (this.searchInterval != null) {
      clearInterval(this.searchInterval);
    }
    var self = this;
    this.searchInterval = setInterval(() => {
      self.search();
      clearInterval(self.searchInterval);
    }, 300);
  }
  changeArrowIndex(keyCode){
    var resultArrowIndex = this.state.resultArrowIndex
    if(keyCode === 40){
      resultArrowIndex += 1;
    }else if(keyCode === 38){
      resultArrowIndex -=1;
    }

    if(resultArrowIndex < -1){
      resultArrowIndex = -1
    }
    if(this.state.resultData != null){

      if(resultArrowIndex >= this.state.resultData.length){
        resultArrowIndex = this.state.resultData.length
      }
    }
    this.setState({resultArrowIndex})
  }
  search() {
    if (!this._isMounted) return;

    const value = this.input.current.value;
    const module = this.props.module;
    var searchFields = ["name"];
    var getFields = ["name", "id"];
    if(this.props.data.searchFields != undefined){
      searchFields = this.props.data.searchFields;
      getFields = getFields.concat(searchFields);
    }

    if (value !== "" || value !== null) {
      const searchData = {
        fields: searchFields,
        getFields: getFields,
        value,
        module,
        defaultFilter: this.props.defaultFilter
      };
      var self = this;
      sAction.quickSearch(searchData, data => {
        if (!this._isMounted) return;

        self.setState({
          resultArrowIndex: -1,
          resultData: data,
          resultOpen: true
        });
      });
    }
  }
  selectItem(item, doUpdate = true,cancelEdit = true) {
    this.setState({
      id:item.id,
      name:item.name,
    })
    this.props.callback(item);
    this.input.current.value = item.name;
    this.cancel();
  }
  focus() {
    if (this.state.resultData != null) {
      this.setState({
        resultOpen: true
      });
    }
  }
  openPopupList() {
    var self = this;
    const data = {
      module: this.props.module,
      selectedActive: false,
      defaultFilter: this.props.defaultFilter
    };
    sAction.openRelatePopup(data, returnData => {
      self.selectItem(returnData);
      sAction.popupHide();
    });
  }
  getSearchResult(){
    var searchResult = null;
    if (this.state.resultOpen === true) {
      var resultList = [];
      this.state.resultData.forEach((item, index) => {

        let itemLabel = item.name;
        if(this.props.formatSearchItemLabel != null){
          itemLabel = this.props.formatSearchItemLabel(item);
        }

        var lineClass = "quickSearchResultLine";
        if (item.id === this.props.data.id_value) {
          lineClass += " select";
        }

        if(index === this.state.resultArrowIndex){
          lineClass += " arrowSelect";
        }

        resultList.push(
          <div
            tabIndex={index}
            onClick={() => this.selectItem(item)}
            key={index}
            className={lineClass}
          >
            {itemLabel}
          </div>
        );
      });
      searchResult = <div className="quickSearchResult">{resultList}</div>;
    }
    return searchResult;
  }
  chechChange(){
    if(this.state.value !== this.input.current.value){
        if (this.props.updateField == true) {
            this.input.current.value = '';
        } else {
            this.props.callback({name : this.input.current.value});
        }
    }
    this.cancel();
  }
  onBlur(e) {
    const relTarget = e.relatedTarget;

    if (relTarget == null) {
      this.chechChange();
    }
    else if (!(e.relatedTarget.className == "quickSearchResultLine")){
      this.chechChange();
    }
  }

  cancel(){
    this.setState({
      resultOpen: false
    });
  }

  componentDidMount(){
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    var containerClassName = this.props.containerClassName ? this.props.containerClassName : null;
    var containerClass = "inputEditContainer " + containerClassName;

    var value = data.value;
    if (newRecord && (value === "" || value === undefined || value === null)) {
      value = "";
    }
    const searchResult = this.getSearchResult();

    const containerClassField = "inputContainer relateField";

    var btnComp = [];
    //buttons={[{className: "detailInfoIcon",  onClick: this.prodInfo(data.id)}]}
    if(this.props.buttons != undefined){
      this.props.buttons.forEach(btn => {
        btnComp.push(<div
          key={btn.id}
          onClick={() => btn.onClick(btn.params)}
          className="inputEditButton"
        >
          <div className={btn.className} />
        </div>)
      });
    }
    return (
      <div className={containerClass} data-fieldname={data.name} style={this.props.containerStyle ? this.props.containerStyle : null}>
        <div className={containerClassField}>
          <InputText
            onKeyDown={e => this.onKeyDown(e)}
            onKeyUp={event => this.keyUp(event)}
            onFocus={() => this.focus()}
            onBlur={e => this.onBlur(e)}
            myRef={this.input}
            id={data.name}
            defaultValue={value}
            label={this.props.data.label}
            InputProps={this.props.InputProps}
            error={this.props.error}
            autoFocus={this.props.autoFocus != undefined ? this.props.autoFocus : false}
            className={this.props.inputClassName ? this.props.inputClassName : null}
          />
          {searchResult}
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            <div
              onClick={() => this.openPopupList()}
              className="inputEditButton openPopupButton"
            >
              {!this.props.hideSearchIcon && (<div className={"icon-search relateSearchIcon"} />)}
            </div>
            {btnComp}
          </div>
        )}
      </div>
    );
  }
}
