export default function convertLeadSave(prefix){
    const data = this.dataGet(prefix);
    const name = data.name;
    const rec = data.record;
    const modules = data.detailViews;
    var sendToCrm = true;

    var self = this;

    var errMsg = "";

    var wrongFields = [];
    var correctFields = [];
    var moduleClasses = [];

    modules.forEach((module, ind) => {
        var customData = module.customData;
        var moduleValid = true;
        if (module.canSave !== true) {
            sendToCrm = false;
            moduleValid = false;
            errMsg += self.translate("LBL_MODULE_NAME", module.module)+": Záznam momentálně nelze uložit<br>";
        }

        //required modules
        if(customData.required){
            if(!customData.checked){
                console.log("CCC", customData);
                if(customData.related_id === undefined){
                    sendToCrm = false;
                    moduleValid = false;
                    errMsg += self.translate("LBL_MODULE_NAME", module.module)+": Tento modul je povinný<br>";
                }
                else if(customData.related_id == undefined || customData.related_id == ""){
                    sendToCrm = false;
                    moduleValid = false;
                    errMsg += self.translate("LBL_MODULE_NAME", module.module)+": Tento modul je povinný<br>";
                }
            }
        }
        
        //required fields
        if(customData.checked){
            const fields = module.fields;
            let hasWrongField = false;
            fields.valueSeq().forEach(field => {
                if(field.name !== "id"){
                if (field.def && field.def.get("required") === true && (field.value === "" || field.value === null)){
                    wrongFields.push({name:field.name, index: ind, class:"error", module: module.module})
                    hasWrongField = true;
                }else if(field.def && field.def.get("required") === true && (field.value != "" || field.value != undefined) && field.customClass == "error"){
                    correctFields.push({name:field.name, index: ind, class:""});
                }
            }
            });

            if(hasWrongField){
                errMsg += self.translate("LBL_MODULE_NAME", module.module)+":  Nejsou vyplněny všechny povinné údaje<br>";
                sendToCrm = false;
                moduleValid = false;
            }
        }

        if(moduleValid){
            moduleClasses.push({index : ind, class : ""});
        }
        else{
            moduleClasses.push({index : ind, class : "error"});
        }
    });
    console.log(wrongFields);
    if(sendToCrm === false){
        self.dsClear();
        wrongFields.forEach(field => {
            self.dsAdd("set",prefix+"/detailViews/"+field.index+"/fields/"+field.name+"/customClass", field.class);
        });

        correctFields.forEach(field => {
            self.dsAdd("set",prefix+"/detailViews/"+field.index+"/fields/"+field.name+"/customClass", field.class);
        });

        moduleClasses.forEach(module => {
            self.dsAdd("set",prefix+"/detailViews/"+module.index+"/customData/customClass", module.class);
        });

        self.dsProcess();
        self.error(errMsg);
    }
    else{
        var params = {record: rec, modules : []};
        self.load();
        modules.forEach((module, moduleIndex) => {
            if(module.customData.checked){
                let changes = module.changes;
                const emails = self.customFieldEmail(prefix+"/detailViews/"+moduleIndex);
                if (emails) {
                    changes = changes.setIn(["fields", "email1"], emails);
                }
                params.modules.push({module:module.module, changes, newRecord:true, beanMark : module.customData.beanMark});
            }
            else if(module.customData.required){
                params.modules.push({module:module.module, record:module.customData.related_id, beanMark : module.customData.beanMark, newRecord:false});
            }
            
        });
        self.rest.post("leadConvertSave", params, msg => {
            if(msg.errCode == "OK"){
               self.dsClear();
               let action = {
                    type: "LEADCONVERTHANDLERESULTS",
                    content: {newBeans : msg.newBeans, name: name},
                    prefix: "view"
                }
                self.dsAddCustom(action);
                self.dsProcess();
            }
            else{
                self.error(msg.errMsg);
            }
            self.unLoad();
        });
    }
}