import { Hidden } from "@material-ui/core";
import React, { Component } from "react";

import InputField from "../inputFieldClass";


export default class Text extends InputField {
  constructor() {
    super();

    this.input = React.createRef();
  }
  componentDidMount(){
    const newRecord = this.props.newRecord;
    if (!newRecord) {
      this.input.current.focus();
    }
  }

  setInitialHeight(heightValue) {
    var count = (heightValue.match(/\n/g) || []).length;
    if (count == 0) {
      return 2;
    }
    return count;
  }
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    const ok = this.state.state;
    var inputClass = "form-control form-control-sm";
    var containerClass = "textareaEditContainer";
    if (ok === false) {
      inputClass += " wrongInput";
    }
    var paddingValue = '0px';
    var st = {};
    if (data.value) {
      if  (this.setInitialHeight(data.value) >2 || this.state.scroll > 50) {
        paddingValue = '20px';
      }
    } 
    var value = data.value;
    if (newRecord && (value == "" || value == undefined)) {
      value = "";
    }
    const style = {
      height: this.state.height,
      paddingBottom: this.state.paddingBottom ? this.state.paddingBottom : paddingValue 
    };
    return (
      <div className={containerClass}>
        <div className="inputContainer">
          <textarea
            onKeyUp={event => event.keyCode == 27 && this.cancel()}
            onChange={event => {
              st.height = (event.target.scrollHeight -15)+'px';
              st.paddingBottom = '20px';
              st.scroll = event.target.scrollHeight;
              this.setState(st);
            }}
            onFocus={(event)=>{
              st.scroll = event.target.scrollHeight;
              this.setState(st);
            }}
            ref={this.input}
            onBlur={() => this.save()}
            onKeyDown={(e) => this.onKeyDown(e)}
            className={inputClass}
            type="text"
            id={data.name}
            defaultValue={value}
            rows={(value) ? this.setInitialHeight(value) : 2}
            style={style}
          />
        </div>
      </div>
    );
  }
}
